<template>
    <div id="topbar" class="fixed-top d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-center justify-content-md-between">
            <div class="cta d-none d-md-block">

            </div>
            <div class="contact-info d-flex align-items-center">
                <span class="topaddress">
                    <font v-if="companyAddress.Address1 !='' || companyAddress.Address1 != null">{{companyAddress.Address1}}<span v-if="companyAddress.Address1 != null">, </span> </font>
                    <font v-if="companyAddress.Address2 !=''">{{companyAddress.Address2}}<span v-if="companyAddress.Address2 != null">, </span> </font>
                    <font v-if="companyAddress.state !=''">{{companyAddress.state}}<span v-if="companyAddress.state != null">, </span> </font>
                    <font v-if="companyAddress.city !=''">{{companyAddress.city}}<span v-if="companyAddress.city != null">, </span> </font>
                    <font v-if="companyAddress.zip !=''">{{companyAddress.zip}}</font>
                </span>
                <span class="line">|</span><a :href="'mailto:'+companyAddress.email" class="underlinetext">{{companyAddress.email}} </a>  <span class="line">|</span>   <a :href="'tel:'+companyAddress.phone">{{companyAddress.phone}}</a>
            </div>

        </div>
    </div>
    <header id="header" class="fixed-top d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">

            <h1 class="logo">
                <!--<a href="#"><img src="../../../../../assets/img/logo.png" alt="logo"></a>-->
                <router-link :to="{ name: 'HomePage'}" @click.native="scrollToTop">
                    <img :src="companyLogo" :alt="companyname">
                </router-link>
            </h1>

            <div class="header-main-nav ">
                <nav id="navbar" class="navbar">
                    <ul>
                        <li><router-link :to="{ name: 'HomePage'}" @click.native="scrollToTop">HOME</router-link></li>
                        <li id="menu-item-17633" class="menu-item" v-for="link in NavLinks">
                            <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu" @click.native="scrollToTop">{{link.displayText}}</router-link>
                        </li>
                        <!--<li><router-link :to="{ name: 'contactus'}">Contact</router-link></li>-->
                        <!--<li><a class="nav-link" href="index.html">HOME</a></li>
                        <li><a class="nav-link" href="local-feed-delivery.html">LOCAL FEED DELIVERY</a></li>
                        <li><a class="nav-link" href="shop.html">PRODUCTS</a></li>
                        <li><a class="nav-link" href="about-us.html">ABOUT</a></li>
                        <li><a class="nav-link" href="#">BLOG</a></li>
                        <li><a class="nav-link" href="contactus.html">CONTACT</a></li>-->
                    </ul>
                </nav><!-- .navbar -->
                <div class="closeMask "></div>
            </div>






            <div class="header-action">
                <ul class="other_icons">
                    <!--<li><a class="nav-link" href="#"><i class="fa fa-solid fa-search"></i></a></li>-->
                    <!--<li class="nav-item"><router-link to="/login" class="nav-link">  <i class="fa fa-regular fa-user"></i></router-link></li>-->
                    <!--<li><a class="nav-link" href="#"> <i class="fa fa-regular fa-heart"></i></a></li>-->
                    <li><a class="nav-link cartbadge" @click="redirecttoCart(Id)"><i class="fas fa-shopping-cart"></i> <span class="label label-shadow label-pill notification-badge" v-on:mouseover="GetCartListData">{{Shopping}}</span></a></li>
                    <li class="axil-mobile-toggle"><button class="menu-btn mobile-nav-toggler"><i class="fa-solid fa-bars"></i></button></li>
                    <li id="mobile_backButton"><a @click="goToPreviousPage()"><i class="fa fa-arrow-left" aria-hidden="true"></i></a></li>
                </ul>

                <i class="bi bi-list mobile-nav-toggle"></i>
            </div>

            <div class="header-action">
                <ul id="mobile-main-nav" class="other_icons" v-if="!isUserLoggedIn">
                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page ">
                        <router-link class="nav-link12" :to="{name:'Login'}" @click="OpenRightMenu">
                            <i class="fa-solid fa-right-to-bracket me-2"></i><span class="hidetxt">Login</span>
                        </router-link>
                    </li>
                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page ml-0">
                        <router-link class="nav-link12" :to="{name:'SignupPage'}" @click="OpenRightMenu">
                            <i class="fa-solid fa-user-plus me-2"></i><span class="hidetxt">Sign Up</span>
                        </router-link>
                    </li>
                </ul>
                <ul id="mobile-main-nav" class="other_icons" v-if="isUserLoggedIn">
                    <!--<li><a class="nav-link" href="/dashboard"><i class="fa-solid fa-house"></i></a></li>-->
                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page dropdown">
                        <a class="nav-link111" href="javascript:;" data-bs-toggle="dropdown">
                            <i class="fa fa-regular fa-user" style="margin-right:3px;"></i> 
                            <!--   <span class="hidemyaccount">My Account</span>-->
                             <span class="hidemyaccount">
                                {{ username }}
                            </span>  <i class="fa-solid fa-caret-down"></i>
                        </a>


                        <ul class="dropdown-menu">
                            <!-- <li class="welcomename" v-if="$filters.IsNull(name,true,null)!=null">  Welcome {{name.firstName}}&nbsp;{{name.lastName}}</li>-->

                            <li> <a class="nav-link111" href="javascript:;" @click="goToPortalDashboard">   My Profile </a></li>
                            <li> <a class="nav-link090" routerlinkactive="active" @click="logoutfrommenu" ng-reflect-router-link-active="active"> Logout</a></li>
                        </ul>
                    </li>

                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page">

                    </li>
                </ul>

            </div>

        </div>
    </header>

</template>
<script>
    import side from '@/views/AuthorizedUserView/AuthorizeUserLayout/SideMenu.vue'
    import ECommerce from '@/Services/frontEndService';
    import * as signalR from '@microsoft/signalr';
    export default {
        name: 'HeaderVue',
        components: {
            side
        },

        emits: ["openSignUpdmodel", "openLoginModel", "logout"],
        data() {
            const hubConnection = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Debug)
                /*.withUrl('https://localhost:7015/userHub', {*/
                .withUrl('https://remoteservice.beenee.com/userHub', {
                    //skipNegotiation: true,
                    //transport: signalR.HttpTransportType.WebSockets
                })
                .build();
            return {
                companyLogo: '',
                IsOpenLeftMenu: false,
                hubConnection,
                IsOpenRightMenu: false,
                companyname: localStorage.getItem('companyName'),
                companyAddress: JSON.parse(localStorage.getItem('companyAddress')),
                totalcount: '',
                headerlength: "",
                Shopping: '',

                userName: '' 
            }
        },
        props: ['NavLinks', 'isUserLoggedIn', 'sideMenuItems', 'name', 'ProfilePic'],
        mounted() {
            debugger;
            this.hubConnection.on('ReceiveMessage', (message, user, count) => {
                this.GetCartListData();             
            });
                // Retrieve username from localStorage when the component is mounted
            const storedUsername = JSON.parse(localStorage.getItem('userinfo'));
            // Check if the username exists in localStorage
            if (storedUsername) {
                // If exists, update the data property
                this.username = storedUsername.userName;
            }

        },
        created() {
          debugger
            this.hubConnection.start({transport:'ServerSentEvents'}).then(() => {
                //alert('This connected for that time');
            });

            this.GetCartListData();
            this.getCompanyLogo();
            //this.prepareSignalR();
            //this.initializeSignalR();
            //if (localstorage.getitem('currentcartid') != '' && localstorage.getitem('currentcartid') != null && localstorage.getitem('userid') != '' && localstorage.getitem('userid') != null)
            //{
            //    this.getcartlistdata();
            //}
             $(document).ready(function () {


                $(".mobile-nav-toggler").click(function () {

                    const element = document.querySelector('.closeMask')
                    const style = getComputedStyle(element)
                    const backgroundColor = style.display

                    if (backgroundColor == 'block') {
                        $(".header-main-nav").removeClass('open');
                        $(".closeMask").removeClass('active');
                    }
                    else {
                        $(".header-main-nav").addClass('open');
                        $(".closeMask").addClass('active');
                    }

                });

                $(".closeMask").click(function () {
                    $(this).removeClass('active');
                    $(".header-main-nav").removeClass('open');
                });
                $(".header-main-nav a").click(function () {
                    $(".closeMask").removeClass('active');
                    $(".header-main-nav").removeClass('open');
                });
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() >= 50) {
                    $('#topbar').addClass('scroll');
                }
                else {
                    $('#topbar').removeClass('scroll');
                }
            });

        },
        methods: {
       
            async initializeSignalR() {
                if (this.$hubConnection.state === signalR.HubConnectionState.Disconnected) {
                    try {
                        await this.$hubConnection.start();
                    } catch (error) {
                        console.error('Error starting SignalR connection:', error);
                    }
                    this.$hubConnection.onreconnected(connectionId => {
                    });
                }
            },
            async GetCartListData() {
                this.isLoading = true;
                await ECommerce.GetCartListData(localStorage.getItem('CurrentCartId'), "yes", localStorage.getItem('cartaction'), localStorage.getItem("userId"), null, false).then((response) => {
                    this.Shopping = response.data._CartsModel.length;
                })
            },
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companySecondaryLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            },
            OpenRightMenu() {
                this.IsOpenRightMenu = (this.IsOpenRightMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let sectioncomparison = document.getElementsByClassName("comparisonblock");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenRightMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < sectioncomparison.length; i++) {
                        sectioncomparison[i].classList.add("show-menu1");
                    }
                    // footerbottom.classList.add("show-menu1");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < sectioncomparison.length; i++) {
                        sectioncomparison[i].classList.remove("show-menu1");
                    }
                    //  footerbottom.classList.remove("show-menu1");
                }

            }, ToggleLeftMenu(Action = null) {
                this.IsOpenLeftMenu = (this.IsOpenLeftMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenLeftMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu");
                    }
                    //footerbottom.classList.add("show-menu");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    //footerbottom.classList.remove("show-menu");
                }
            },
            CloseLeftMenu() {
                if (this.IsOpenLeftMenu) {
                    let navbar = document.getElementsByClassName("navbar-default");
                    let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                    let sectionFooter = document.getElementsByClassName("section-footer");

                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    this.IsOpenLeftMenu = false

                }
            },
            goToPortalDashboard() {
                this.OpenRightMenu();
                /*        this.$emit('goToPortal')*/
                this.$router.push({ path: `/myprofile` })
            },
            logoutfrommenu() {
                this.OpenRightMenu();
                /* this.$router.push({ path: `/Login` })*/
                this.$emit('logout');
            },
            redirecttoCart(Id) {
                if (localStorage.getItem('userlogin') == "false") {
                    this.$router.push({ path: `/Login` })
                }
                else {
                    //let subscriptionInfo = localStorage.getItem("SubscriptionDetails");
                    //var subscriptiondata = JSON.parse(subscriptionInfo);
                    //if (subscriptiondata != null) {
                    //    if (subscriptiondata.subscriptiontype != null || subscriptiondata.subscriptiontype != 'undefined') {
                    //        this.error("You cannot add more than one product in subscription!");
                    //        return;
                    //    }
                    //}
                    localStorage.setItem('cartaction', 'cartcalc');
                    this.$router.push({ path: `/Cart` })
                }
                //this.$router.push({ path: `/Cart` })
                //this.$router.push({ path: `/Cart/${Id}` })-old
            },

            scrollToTop() {
                window.scrollTo(0, 0);
            },
            goToPreviousPage() {
                this.$router.go(-1);
            },
            //GetCartListData()
            //{

            //    var vm = this;
            //    vm.productId = localStorage.getItem('CurrentCartId');
            //    vm.CustomerLoginUserIds = localStorage.getItem('userId');
            //    vm.CurrentCartId = localStorage.getItem('CurrentCartId');
            //    ECommerce.GetCartListData(vm.productId, vm.removePromo, vm.CustomerLoginUserIds, vm.CurrentCartId).then((response) => {
            //        debugger;
            //        vm.totalcount = response.data._CartsModel.length;
            //        vm.data = response.data._CartsModel;
            //        vm.Id = vm.data[0].id;
            //        vm.loading = false;
            //    })
            //},


        }
    }
</script>

<style>


    ul.other_icons li a.cartbadge .notification-badge {
        position: absolute;
        top: 11px;
        background: #fdb614;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        text-align: center;
        color: #000;
        line-height: 22px;
        font-size: 13px;
        font-weight: bold;
        right: -14px;
    }
</style>